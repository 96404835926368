<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3333 7.99992V6.33325H16.6667V7.99992H15V9.66658H16.6667V11.3333H18.3333V9.66658H20V7.99992H18.3333Z"
      fill="currentColor"
    />
    <path
      d="M6.66667 10.4999C8.50833 10.4999 10 9.00825 10 7.16659C10 5.32492 8.50833 3.83325 6.66667 3.83325C4.825 3.83325 3.33333 5.32492 3.33333 7.16659C3.33333 9.00825 4.825 10.4999 6.66667 10.4999ZM6.66667 5.49992C7.58333 5.49992 8.33333 6.24992 8.33333 7.16659C8.33333 8.08325 7.58333 8.83325 6.66667 8.83325C5.75 8.83325 5 8.08325 5 7.16659C5 6.24992 5.75 5.49992 6.66667 5.49992Z"
      fill="currentColor"
    />
    <path
      d="M6.66667 11.3333C4.44167 11.3333 0 12.4499 0 14.6666V17.1666H13.3333V14.6666C13.3333 12.4499 8.89167 11.3333 6.66667 11.3333ZM11.6667 15.4999H1.66667V14.6749C1.83333 14.0749 4.41667 12.9999 6.66667 12.9999C8.91667 12.9999 11.5 14.0749 11.6667 14.6666V15.4999Z"
      fill="currentColor"
    />
    <path
      d="M10.425 3.87492C11.1917 4.75825 11.6667 5.90825 11.6667 7.16659C11.6667 8.42492 11.1917 9.57492 10.425 10.4583C12.0583 10.2499 13.3333 8.86659 13.3333 7.16659C13.3333 5.46659 12.0583 4.08325 10.425 3.87492Z"
      fill="currentColor"
    />
    <path
      d="M13.775 12.0249C14.5167 12.7166 15 13.5833 15 14.6666V17.1666H16.6667V14.6666C16.6667 13.4583 15.3417 12.5749 13.775 12.0249Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcPositivePeople',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
